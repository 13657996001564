<template>
  <BaseDialog :model-value="true" size-class="!max-w-6xl h-[34rem]" @update:model-value="$emit('close')">
    <template #header>Settings </template>
    <template #default>
      <div class="flex h-full">
        <div class="w-72 border-r border-grid">
          <BaseNavRadio v-model="selectedItem">
            <BaseNavRadioOption v-for="item in filteredSidebarItems" :key="item.id" :option="item.id">
              {{ item.text }}
            </BaseNavRadioOption>
          </BaseNavRadio>
        </div>
        <div v-if="selectedItem == 'profile'" class="w-full">
          <div class="flex flex-col p-10">
            <div class="flex space-x-16">
              <div class="space-y-4 pt-3">
                <div class="flex w-80 space-x-4">
                  <div class="space-y-2">
                    <div class="text-xs font-medium text-gray-400">First Name</div>
                    <input
                      v-model="profile.first_name"
                      class="h-8 w-full rounded bg-gray-800/50 px-3 text-sm font-medium text-gray-100"
                    />
                  </div>
                  <div class="space-y-2">
                    <div class="text-xs font-medium text-gray-400">Last Name</div>
                    <input
                      v-model="profile.last_name"
                      class="h-8 w-full rounded bg-gray-800/50 px-3 text-sm font-medium text-gray-100"
                    />
                  </div>
                </div>
                <div class="w-80 space-y-2">
                  <div class="text-xs font-medium text-gray-400">Title</div>
                  <input
                    v-model="profile.role_title"
                    placeholder="Role Title"
                    class="h-9 w-full rounded bg-gray-800/50 px-3 text-sm font-medium text-gray-100"
                  />
                </div>
                <div class="w-80 space-y-2">
                  <div class="text-xs font-medium text-gray-400">About Me</div>
                  <textarea
                    v-model="profile.about"
                    rows="4"
                    class="w-full resize-none rounded bg-gray-800/50 px-3 text-sm font-medium text-gray-100"
                    placeholder="About"
                  />
                </div>
              </div>
              <div class="flex w-2/5 flex-col items-center justify-center">
                <ImageSelector :settings-modal="true" :user="streamUser" @selected-image="setImage" />
              </div>
            </div>
          </div>
          <div class="flex w-full items-center justify-end space-x-2 pb-3 pr-4">
            <BaseButton :disabled="buttonBusy" type="secondary" @press="$emit('close')">Cancel</BaseButton>
            <BaseButton :busy="buttonBusy" @press="saveProfile">Save</BaseButton>
          </div>
        </div>
        <div v-if="selectedItem === 'notification'" class="w-full">
          <div class="flex h-full flex-col justify-between px-8 pr-12 pt-4">
            <div class="space-y-2">
              <span class="px-2 text-xs font-medium leading-4 text-gray-400">Sound Notifications</span>
              <div class="flex items-center justify-between rounded bg-gray-800/25 p-2 pt-1">
                <span class="text-xs font-medium leading-4 text-gray-100">New Message</span>
                <BaseToggle v-model="messageNotificationSound">
                  <template #left>Off</template>
                  <template #right>On</template>
                </BaseToggle>
              </div>
            </div>
            <div class="flex w-full justify-end space-x-2 pb-3">
              <div class="flex items-center space-x-2">
                <BaseButton :disabled="buttonBusy" type="secondary" @press="$emit('close')"> Cancel </BaseButton>
                <BaseButton :busy="buttonBusy" @press="saveNotificationSetting">Save</BaseButton>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedItem === 'privacy'" class="w-full">
          <div class="flex h-full flex-col justify-between px-8 pr-12 pt-4">
            <div class="space-y-2">
              <span class="px-2 text-xs font-medium leading-4 text-gray-400">Privacy Settings</span>
              <div class="flex items-center justify-between rounded bg-gray-800/25 p-2 pt-1">
                <span class="text-xs font-medium leading-4 text-gray-100">
                  Searchable by other users on the Terminal
                </span>
                <BaseToggle v-model="searchable">
                  <template #left>Off</template>
                  <template #right>On</template>
                </BaseToggle>
              </div>
            </div>
            <div class="flex w-full justify-end space-x-2">
              <div class="flex items-center space-x-2">
                <BaseButton :disabled="buttonBusy" type="secondary" @press="$emit('close')"> Cancel </BaseButton>
                <BaseButton :busy="buttonBusy" @press="saveSearchable">Save</BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import ImageSelector from './ChatImageSelector.vue';
import useHttp from '@/composeables/http';

const $http = useHttp();
const $store = useStore();
const $emit = defineEmits(['close']);

// PROFILE
const profile = reactive({
  first_name: '',
  last_name: '',
  role_title: '',
  about: '',
  image: ''
});

function getProfile() {
  $http.get('/profile').then(response => {
    profile.first_name = response.data.first_name;
    profile.last_name = response.data.last_name;
    profile.about = response.data.about;
    profile.role_title = response.data.role_title;
    searchable.value = response.data.searchable_in_messenger;
  });
}

const buttonBusy = ref(false);
async function saveProfile() {
  buttonBusy.value = true;
  let formData = new FormData();

  Object.entries(profile).forEach(([key, value]) => {
    if (value != null) {
      formData.append(`user[${key}]`, value);
    }
  });
  formData.append('_method', 'PATCH');
  await $http.patch('/profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  buttonBusy.value = false;
  $emit('close');
}

onMounted(() => {
  getProfile();
});

function setImage(data) {
  profile.image = data.file;
}

// NOTIFICATION
const client = $store.getters.client;
const messageNotificationSound = ref(false);
onMounted(() => {
  messageNotificationSound.value = client.user.message_notification_sound;
});

async function saveNotificationSetting() {
  buttonBusy.value = true;
  const update = {
    id: client.user.id,
    set: {
      message_notification_sound: messageNotificationSound.value
    }
  };
  let response = await client.partialUpdateUser(update);
  buttonBusy.value = false;
  $emit('close');
}

// SIDEBAR ITEMS
const sidebarItems = reactive([
  { id: 'profile', text: 'Profile' },
  { id: 'notification', text: 'Notifications' },
  { id: 'privacy', text: 'Privacy' }
  // {
  //   id: 'export',
  //   text: 'Export'
  // }
]);

// SEARCHABLE
const searchable = ref(false);
async function saveSearchable() {
  await $http.patch('/profile', { user: { searchable_in_messenger: searchable.value } });
  $emit('close');
}

// OTHERS
const streamUser = computed(() => {
  return $store.getters.streamUser;
});
const selectedItem = ref('profile');
</script>
